import React from 'react'
import { graphql } from 'gatsby'
import Map from '../components/map'

const IndexPage = ({ data }) => {
  const { allFile, allMarkdownRemark } = data
  return <Map iconImageNodes={allFile.nodes} nodes={allMarkdownRemark.nodes} />
}

export const query = graphql`
  query {
    allFile(filter: { sourceInstanceName: { eq: "icons" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(placeholder: DOMINANT_COLOR, width: 64)
        }
        name
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(/reviews/)/" } }
    ) {
      nodes {
        frontmatter {
          awards
          bagelIconColor
          bagelScore
          creamCheeseIconColor
          creamCheeseNA
          creamCheeseScore
          creamCheeseReview
          isClosed
          locationLat
          locationLng
          overallIconColor
          pullQuote
          reviewImage {
            childImageSharp {
              gatsbyImageData(placeholder: DOMINANT_COLOR)
            }
          }
          storeIconColor
          storeNA
          storeName
          storeScore
        }
        reviewPath: gatsbyPath(
          filePath: "/reviews/{MarkdownRemark.frontmatter__storeName}"
        )
      }
    }
  }
`

export default IndexPage
